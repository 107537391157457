import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import VueApexCharts from "vue3-apexcharts";
import { select_leng } from "./loadSessionData";

loadFonts();

async function initializeApp() {
  try {
    await select_leng();
  } catch (error) {
    console.error("Error loading session data:", error);
  }

  const app = createApp(App);
  app.use(router).use(store).use(vuetify).use(VueApexCharts).mount("#app");
}

initializeApp();
